<template>
	<div v-if="serverBusy" id="server-activity">
		<span class="loading-icon"></span>
		<span class="loading-message">{{ busyText }}</span>
	</div>
	<div class="table-container">
		<table class="buyIn-list">
			<tr class="sticky">
				<th>Name</th>
				<th>Priority</th>
				<th>Start Date</th>
				<th>End Date</th>
				<th>Min Buy</th>
				<th>Max Buy</th>
				<th>
					Regular Credit Per
					{{
						systemCurrencyTool.formatFromFractionalAU(
							systemCurrencyTool.currencyInfo.auPerCurrencyUnit,
							systemCurrencyTool.currencyInfo.minorDigits
						)
					}}
				</th>
				<th>
					Promo Credit Per
					{{
						systemCurrencyTool.formatFromFractionalAU(
							systemCurrencyTool.currencyInfo.auPerCurrencyUnit,
							systemCurrencyTool.currencyInfo.minorDigits
						)
					}}
				</th>
				<th>Cashier</th>
				<th>MRU</th>
				<th>Web</th>
				<th>Obsolete</th>
			</tr>
			<tr class="buyIn-row" :class="buyIn.isExpired ? 'expired' : ''" v-for="(buyIn, index) in buyIns" :key="index" :value="buyIn.id">
				<td>{{ buyIn.name }}</td>
				<td class="center">{{ buyIn.priority }}</td>
				<td>{{ new Date(buyIn.startDate).toLocaleTimeString([], shortDateOptions) }}</td>
				<td>
					{{ new Date(buyIn.endDate).toLocaleTimeString([], shortDateOptions) }}
					<span v-if="buyIn.isExpired" class="expired-text">EXPIRED</span>
				</td>
				<td class="cash">{{ systemCurrencyTool.formatCurrency(buyIn.minimumCOC, systemFull) }}</td>
				<td class="cash">{{ systemCurrencyTool.formatCurrency(buyIn.maximumCOC, systemFull) }}</td>
				<td class="cash">{{ systemCurrencyTool.formatFromFractionalAU(buyIn.creditRegularAUPerCashCOC, 4) }}</td>
				<td class="cash">{{ systemCurrencyTool.formatFromFractionalAU(buyIn.creditPromoAUPerCashCOC, 4) }}</td>
				<td class="check-status" :title="buyIn.cashierAllowed ? 'Cashier Allowed' : 'Cashier Not Allowed'">
					{{ buyIn.cashierAllowed ? "✅" : "🚫" }}
				</td>
				<td class="check-status" :title="buyIn.mruAllowed ? 'MRU Allowed' : 'MRU Not Allowed'">{{ buyIn.mruAllowed ? "✅" : "🚫" }}</td>
				<td class="check-status" :title="buyIn.webAllowed ? 'Web Allowed' : 'Web Not Allowed'">{{ buyIn.webAllowed ? "✅" : "🚫" }}</td>
				<td class="check-status" :class="buyIn.isObsolete ? 'obsolete' : 'active'" :title="buyIn.isObsolete ? 'Obsolete' : 'Active'">
					{{ buyIn.isObsolete ? "🔴" : "" }}
					<button class="btn" v-if="!buyIn.isObsolete" title="Click to make this offer obsolete." @click="setBuyInObsolete(buyIn)">
						Make Obsolete
					</button>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "BuyInsTable",
	props: {
		buyIns: Array,
		adminState: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			inGameMinorWholeOrFull: this.inGameCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
			serverBusy: false,
			busyText: "",
		};
	},
	methods: {
		async setBuyInObsolete(buyIn) {
			if (buyIn.isObsolete) return;

			let confirmObsolesce = confirm(`Are you sure you want to set ${buyIn.name} to obsolete?`);
			if (!confirmObsolesce) return;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			this.busyText = `Setting ${buyIn.name} to obsolete.`;
			this.serverBusy = true;
			this.status.ok = true;

			let requestUrl = new URL(`/api/v1/offer/${buyIn.id}/make-obsolete`, this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.status.message = `${dataJson.name} is now obsolete.`;
				this.status.code = fetchStatus.code;
				this.status.ok = fetchStatus.ok;
				this.eventBus.emit("updateStatus", this.status);

				this.eventBus.emit("updateBuyInList");

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#server-activity {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: grid;
	align-content: center;
	z-index: 2;
}

.table-container {
	padding-bottom: 15px;
}

.sticky {
	top: -15px;
}

.buyIn-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.buyIn-list td {
	border: 1px #000 solid;
}

.buyIn-list th,
.buyIn-list td {
	padding: 5px 10px;
}

.details {
	overflow-wrap: anywhere;
}

.buyIn-row {
	color: #000;
	background-color: #9b9b9b;
}

.buyIn-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.buyIn-row {
	/* cursor: pointer; */
	transition: background-color 0.5s ease, color 0.5s ease;
}

tr.buyIn-row:hover {
	background-color: #5b88bf;
	color: white;
}

.check-status {
	text-align: center;
	font-weight: bold;
	cursor: help;
}

.active {
	cursor: pointer;
}

.buyIn-row.expired {
	background-color: #3f3f3f !important;
	color: #808080;
}

.expired-text {
	color: #f00;
	font-weight: bold;
}

.cash {
	white-space: pre;
	text-align: right;
}

.center {
	text-align: center;
}
</style>
